<template>
  <section class="dtc-main-section">
    <div class="dtc-save-print-btn d-print-none">
      <b-button variant="success" @click="save" class="d-print-none mr-2">
        <i class="fas fa-save mr-1"></i>儲存變更
      </b-button>
      <b-button variant="danger" @click="printDtxPage" class="d-print-none" hidden>
        <i class="fas fa-print mr-1"></i>列印
      </b-button>
    </div>
    <h3>申請書</h3>
    <div class="dtc-words test">
      本人
      <input v-model="map.StudentName" />係﹙
      <input type="checkbox" id="p1" v-model="map.Zone1" />
      <label for="p1">離島</label>
      <input type="checkbox" id="p2" v-model="map.Zone2" />
      <label for="p2">原住民</label>﹚籍
      <input v-model="map.SchoolName" />醫學大學畢業之養成公費生，已取得
      <input v-model="map.TitleName" />字第
      <input v-model="map.LicenseNo" />
      號證書，
      依衛生福利部原住民及離島地區醫事人員養成計畫公費生服務管理要點之規定，公費畢業生應自行覓妥訓練醫院(服務單位)，向衛生福利部申請分發訓練(服務單位)。
      <br />
      <br />本人已覓妥
      <input v-model="map.HealthName" />醫院(服務單位)﹙地址：
      <b-form-select v-model="map.Country" :options="countrys" style="max-width:120px"></b-form-select>
      <input v-model="map.AreaName" />區
      <input v-model="map.AddrsRoad" />路
      <input v-model="map.AddrsNo" />號﹚，自
      <input v-model="map.TwYear" />年
      <input v-model="map.TwMonth" />月
      <input v-model="map.TwDay" />日起接受
      <input v-model="map.TrainingYear" />年之訓練(服務)，請准予核備。
    </div>
    <footer class="dtc-f1">
      <div>
        <div>申請人：</div>
        <input v-model="map.ApplyName" />
      </div>
      <div>
        <div>身分證字號：</div>
        <input v-model="map.ApplyId" />
      </div>
      <div>
        <div>地址：</div>
        <input v-model="map.ApplyAddrs" />
      </div>
      <div>
        <div>住宅電話：</div>
        <input v-model="map.ApplyTel" />
      </div>
      <div>
        <div>行動電話：</div>
        <input v-model="map.ApplyMobile" />
      </div>
    </footer>

    <div class="dtc-f2">
      中華民國
      <input v-model="year" />年
      <input v-model="month" />月
      <input v-model="day" />日
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";

export default {
  name: "totalSpend",
  data() {
    return {
      countrys: [],
      map: {},
      method: "post",
      year: "",
      month: "",
      day: ""
    };
  },

  methods: {
    printDtxPage() {
      window.print();
    },
    async save() {
      if (this.year && this.month && this.day) {
        const year = Number(this.year) + 1911;
        this.month = this.month.length == 1 ? "0" + this.month : this.month;
        this.day = this.day.length == 1 ? "0" + this.day : this.day;
        this.map.CreateDate = year + "-" + this.month + "-" + this.day + zero;
      }
      try {
        if (this.method == "post") {
          await window.axios.post(urlPost, this.map);
          //await window.axios.put(urlPut, this.map);
        } else {
          await window.axios.put(urlPut, this.map);
        }
        this.$root.$emit("dtc-save-form-ok");
      } catch (e) {
        alert("save fail " + e);
      }
    }
  },
  async mounted() {
    try {
      const {
        Identifier = "H102463549",
        SchoolName = "臺北醫學大學",
        method = "post"
      } = queryString.parse(location.href.split("?")[1]);
      this.method = method;
      this.map = await window.axios.get(urlGet + Identifier);
      const [year, month, day] = this.map.CreateDate
        ? this.map.CreateDate.split("T")[0].split("-")
        : {};

      if (year) {
        this.year = Number(year) - 1911;
        this.month = month;
        this.day = day;
      }
    } catch (e) {
    } finally {
    }
  },
  beforeMount() {
    this.countrys = JSON.parse(localStorage.dtcCountry);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  max-width: 890px;
  margin: auto;
  display: grid;
  --dtc-border-color: var(--dark);
  input {
    max-height: 30px;
    display: inline-block;
    margin: 0 12px;
    border: none;
    border-bottom: 1px solid var(--dtc-border-color);
    outline: none;
  }
}
.dtc-words {
  line-height: 40px;
  input {
    text-align: center;
  }
}
footer {
  display: grid;
  margin-top: 3rem;
}
footer {
  > div {
    margin: 8px 0;
    display: grid;
    input {
      min-width: 600px;
    }
    grid-template-columns: 120px max-content;
  }
}
.dtc-f2 {
  margin-top: 3rem;
  input {
    text-align: center;
  }
}
h3 {
  margin: auto;
  margin-bottom: 18px;
}
@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

@media print {
  @page {
    //size: A4 landscape;
  }
  .dtc-main-section {
    --dtc-border-color: var(--dark);
  }
}
</style>
